import { EmotionsMetadata } from '@wix/comments-ooi-client/reactions';
import React from 'react';
import { unreachable } from '../../../../utils/ts-utils';
import { OpenReactionsModalProps } from '../../../actions/open-reactions-modal-actions';
import { ReactionsDisplayState } from '../../../containers/post-reactions/post-reaction-types';
import { ReactionsConfig } from '../../../containers/post-reactions/post-reactions-config.utils';

export type ReactionsCountProps = {
  reactions: ReactionsDisplayState;
  reactionsConfig: ReactionsConfig;
  openReactionsModal: (props: OpenReactionsModalProps) => void;
  postId: string;
  t: any;
  isMobile: boolean;
  className?: string;
};

export const ReactionsCount: React.FC<ReactionsCountProps> = ({
  reactions,
  reactionsConfig,
  openReactionsModal,
  postId,
  t,
  isMobile,
  className,
}) => {
  switch (reactionsConfig.type) {
    case 'emotions':
    case 'likes':
      return (
        <EmotionsMetadata
          reactions={reactions.reactions}
          onClick={() =>
            openReactionsModal({
              postId,
              reactionsConfig,
              reactions,
            })
          }
          t={t}
          config={reactionsConfig}
          isMobile={isMobile}
          className={className}
        />
      );
    case 'none':
      return null;
    default:
      console.error(unreachable(reactionsConfig));
      return null;
  }
};
