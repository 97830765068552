import { unreachable } from '../../../utils/ts-utils';
import { ReactionsConfig } from '../../containers/post-reactions/post-reactions-config.utils';
import { PostMultiRowFooterOrigin } from './post-multi-row-footer/post-multi-row-footer';
import { PostFooterOrigin } from './post-single-row-footer';

type DiplayItem = {
  isCommentsCountVisible: boolean;
  isPostViewsCountVisible: boolean;
  isReactionsCountVisible: boolean;
  isReactionsButtonVisible: boolean;
  isShareButtonVisible: boolean;
  isCommentButtonVisible: boolean;
  isRecentActivityVisible: boolean;
};

export const getPostFooterVariantByOrigin = ({
  origin,
  isCommentsEnabled,
  reactionsConfig,
  canShare,
  isViewsCountEnabled,
}: {
  origin: PostFooterOrigin | PostMultiRowFooterOrigin;
  isViewsCountEnabled: boolean;
  isCommentsEnabled?: boolean;
  reactionsConfig: ReactionsConfig;
  canShare?: boolean;
}): DiplayItem => {
  const showReactions = reactionsConfig.type !== 'none';

  const defaultValues = {
    isCommentsCountVisible: false,
    isPostViewsCountVisible: false,
    isReactionsCountVisible: showReactions,
    isReactionsButtonVisible: showReactions,
    isShareButtonVisible: false,
    isCommentButtonVisible: false,
    isRecentActivityVisible: false,
  };

  switch (origin) {
    case 'footerPostsItem':
      return {
        ...defaultValues,
        isCommentsCountVisible: true,
        isPostViewsCountVisible: true && isViewsCountEnabled,
      };
    case 'feedItem':
      return {
        ...defaultValues,
        isCommentButtonVisible: true,
        isCommentsCountVisible: true,
        isRecentActivityVisible: !!isCommentsEnabled,
      };
    case 'postPage':
      return {
        ...defaultValues,
        isShareButtonVisible: true,
      };
    case 'postListCard':
    case 'postListItem':
      return {
        ...defaultValues,
        isCommentButtonVisible: true,
        isCommentsCountVisible: true,
        isPostViewsCountVisible: true && isViewsCountEnabled,
        isRecentActivityVisible: true,
      };
    case 'postPageMobile':
      return {
        ...defaultValues,
        isPostViewsCountVisible: true && isViewsCountEnabled,
        isCommentsCountVisible: true,
        isCommentButtonVisible: true,
        isShareButtonVisible: !!canShare,
      };
    case 'recentPostsWidgetDesktop':
      return {
        ...defaultValues,
        isCommentsCountVisible: true,
        isPostViewsCountVisible: true && isViewsCountEnabled,
      };
    case 'recentPostsWidgetMobile':
      return {
        ...defaultValues,
        isCommentsCountVisible: true,
      };
    default:
      throw unreachable(origin);
  }
};
