import { useApi } from '../../common/api-providers/use-api';
import { isAuthenticated } from '../../common/store/current-user/current-user-selectors';
import { keepFocus } from '../services/keep-focus';

// TODO: reactions - add BI (?)
export const useEnsureAuth = () => {
  const { isUserAuthenticated, requestLogin, setSavedAction } = useApi((state, actions) => ({
    isUserAuthenticated: isAuthenticated(state),
    requestLogin: keepFocus(actions.requestLoginPromisified),
    setSavedAction: actions.setSavedAction,
  }));

  return (
    cb: () => void,
    options: {
      actionDetails?: { action: string; args: any };
      requestLoginMode?: 'login' | 'signup';
      beforeRequestLogin?: () => void;
      reportBI?: () => void;
    },

    // actionDetails?: { action: string; args: any },
    // requestLoginMode?: 'login' | 'signup',
    // beforeRequestLogin?: () => void,
    // reportBI?: () => void,
  ) => {
    const { reportBI, beforeRequestLogin, actionDetails, requestLoginMode } = options;

    reportBI?.();

    if (isUserAuthenticated) {
      return cb();
    }

    beforeRequestLogin?.();
    actionDetails && setSavedAction(actionDetails);
    requestLogin(requestLoginMode);
  };
};
