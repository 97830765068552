import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { useReactionsConfig } from '../../hooks/use-reactions-config';
import { ReactionsButton } from './action-buttons/reactions-button';
import { CommentButton as CommentActionComponent } from './action-buttons/comment-button';
import { PostMetadata } from './metadata/post-metadata';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import PostWithSidebarSocialActions from '../post-social-actions';
import { VerticalSeparator } from '../separator';
import PostListItemRecentActivity from '../post-list-item-recent-activity';
import { getPostFooterVariantByOrigin } from './post-footer-utils';
import { mapPostFooterRuntimeToProps, PostFooterRuntimeProps } from './post-footer-props-mapper';
import classNames from 'classnames';

import { classes } from './post-reactions.st.css';
import styles from './post-single-row-footer.scss';

export type PostFooterOrigin =
  | 'footerPostsItem'
  | 'feedItem'
  | 'postPage'
  | 'recentPostsWidgetDesktop'
  | 'recentPostsWidgetMobile';

type PostSingleRowFooterProps = {
  post: any;
  category: any;
  origin: PostFooterOrigin;
  isCommentsEnabled?: boolean;
  className?: string;
  actionsClassName?: string;
  metadataClassName?: string;
  isMobile?: boolean;
};

type PostFooterHOCProps = {
  forPublicUser: any;
  t: any;
  can: any;
};

const PostSingleRowFooterComponent: React.FC<
  PostSingleRowFooterProps & PostFooterHOCProps & PostFooterRuntimeProps
> = ({
  post,
  category,
  origin,
  isCommentsEnabled,
  forPublicUser,
  t,
  reactions,
  onReactionClick,
  openReactionsModal,
  can,
  isShareButtonEnabled,
  className,
  actionsClassName,
  metadataClassName,
  isViewsCountEnabled,
  isMobile,
}) => {
  const postLink = `/${category?.slug}/${post?.slug}`;
  const reactionsConfig = useReactionsConfig(category);
  const {
    isReactionsButtonVisible,
    isCommentsCountVisible,
    isPostViewsCountVisible,
    isReactionsCountVisible,
    isShareButtonVisible,
    isCommentButtonVisible,
    isRecentActivityVisible,
  } = getPostFooterVariantByOrigin({
    origin,
    isCommentsEnabled,
    reactionsConfig,
    isViewsCountEnabled,
    canShare: can('read', 'post', post) && isShareButtonEnabled,
  });

  const secondaryActionComponent = isCommentButtonVisible ? (
    <CommentActionComponent post={post} postLink={postLink} t={t} type="action" />
  ) : isShareButtonVisible ? (
    <PostWithSidebarSocialActions
      post={post}
      category={category}
      className={classNames(styles.socialActions)}
      path={`/${category?.slug}/${post?.slug}`}
    />
  ) : null;

  return (
    <div
      className={classNames(styles.container, classes.rootReactionsVars, className, {
        [styles.spaceBetween]: isReactionsButtonVisible,
      })}
    >
      <div className={classNames(styles.leftSide, styles.actions, actionsClassName)}>
        {isReactionsButtonVisible && (
          <div className={styles.action}>
            <ReactionsButton
              reactions={reactions}
              reactionsConfig={reactionsConfig}
              forPublicUser={forPublicUser}
              onReactionClick={onReactionClick}
              postId={post._id}
              t={t}
              isMobile={!!isMobile}
            />
          </div>
        )}
        {secondaryActionComponent !== null && (
          <div className={styles.action}>{secondaryActionComponent}</div>
        )}
      </div>
      <div
        className={classNames(
          styles.rightSide,
          styles.metadata,
          classes.reactionsMetadata,
          metadataClassName,
        )}
      >
        <PostMetadata
          post={post}
          postLink={postLink}
          t={t}
          reactions={reactions}
          isMobile={!!isMobile}
          reactionsConfig={reactionsConfig}
          openReactionsModal={openReactionsModal}
          showReactionsCount={isReactionsCountVisible}
          showPostViewsCount={isPostViewsCountVisible}
          showCommentsCount={isCommentsCountVisible}
          commentsCountShouldOpenPost={
            origin === 'recentPostsWidgetDesktop' || origin === 'recentPostsWidgetMobile'
              ? false
              : true
          }
        />
        {isRecentActivityVisible && <VerticalSeparator className={styles.verticalSeparator} />}
        {isRecentActivityVisible && (
          <PostListItemRecentActivity post={post} category={category} isAvatarSmall={true} />
        )}
      </div>
    </div>
  );
};

export const PostSingleRowFooter = flowRight(
  connect(mapPostFooterRuntimeToProps),
  withAuth,
  withPermissions,
  withTranslate,
)(PostSingleRowFooterComponent);
